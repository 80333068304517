<template>
  <div class="test">
    <el-row >
      <el-col :span="3" class="left">
        <el-menu
            :router="true"
            default-active="/paper/"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose">
          <el-menu-item index="/paper/" class="demo-item">
            <i class="fa fa-file-text"></i>
            <span slot="title">试卷库</span>
          </el-menu-item>
          <el-menu-item index="/paper/paper" class="demo-item">
            <i class="fa fa-plus-circle"></i>
            <span slot="title">试题库</span>
          </el-menu-item>
          <el-menu-item index="/paper/answer" class="demo-item">
            <i class="fa fa-commenting-o"></i>
            <span slot="title">讨论区</span>
          </el-menu-item>
          <el-submenu class="demo-item" index="/paper/self">
            <template slot="title">
              <i class="fa fa-home"></i>
              <span>个人中心</span>
            </template>
            <el-menu-item index="/paper/self">
              <i class="fa fa-paperclip"></i>
              <span slot="title">个人试卷库</span>
            </el-menu-item>
            <el-menu-item index="/paper/addPaper" class="demo-item">
              <i class="fa fa-google-plus-circle"></i>
              <span slot="title">添加个人题目</span>
            </el-menu-item>
            <el-menu-item index="/paper/error" class="demo-item">
              <i class="fa fa-exclamation-triangle"></i>
              <span slot="title">错题集</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-col>
      <el-col :span="21" class="right">
        <router-view></router-view>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    handleOpen(key, keyPath) {
    },
    handleClose(key, keyPath) {
    }
  }
}
</script>
<style scoped>
.demo-item i {
  margin-right: 10px;
}

.el-menu-vertical-demo {
  border: 1px solid rgb(238, 241, 246);
  width: 100%;
}

.demo-item {
  text-align: left;
  margin: 0 auto;

}
.test {
  min-height: calc(100vh - 120px);
  width: 100%;
  /*overflow: hidden;*/
}
</style>
